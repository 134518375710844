import * as React from 'react'
import ReactModal from 'react-modal'

import ModalFooter from './modal-footer'
import * as S from './modal-content.style'

interface Props {
  header?: React.ReactNode
  footer?: React.ReactNode
  children: ReactModal.Props['children']
  as?: 'form' | 'div'
}

const ModalContent = React.forwardRef<HTMLFormElement | HTMLDivElement, Props>(
  ({ header, footer, children, as = 'div' }, ref) => {
    const Container = React.useCallback(
      ({ children }: { children: React.ReactNode }) => {
        if (as === 'div') {
          return (
            <S.StyledModalContent
              className="--flex-column"
              ref={ref as React.ForwardedRef<HTMLDivElement>}>
              {children}
            </S.StyledModalContent>
          )
        } else {
          return (
            <S.StyledModalContentForm
              className="--flex-column"
              ref={ref as React.ForwardedRef<HTMLFormElement>}>
              {children}
            </S.StyledModalContentForm>
          )
        }
      },
      [as, ref],
    )
    return (
      <Container>
        {header}
        <div className="--flex-column body">{children}</div>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </Container>
    )
  },
)

ModalContent.displayName = 'ModalContent'

export default ModalContent
